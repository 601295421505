import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./App.css";

function App() {
  const settings = {
    dots: false,          // Adds navigation dots
    infinite: true,      // Loops the carousel
    speed: 500,          // Transition speed
    slidesToShow: 1,     // Number of slides visible
    slidesToScroll: 1,   // Number of slides to scroll at once
    autoplay: true,      // Auto-scroll
    autoplaySpeed: 5000, // Speed of auto-scroll
  };

  return (
    <div className="App">
      {/* Header Section */}
      <header className="App-header">
        <img src="header.webp" alt="Header" className="header-image" />
      </header>

      {/* Main Content Section */}
      <div className="Main">
      <h1 className="text">$KEVIN</h1>
        <div className="Main-content">
          <img src="kevin.png" alt="Kevin" className="Main-image" />
          <br></br>
          <h2 className="Main-text">
            Yeah, you know I'm kind of the original chill guy, but it's whatever.
            I'm just happy to be here. <br>
            </br>
                      </h2>
          
        </div>

        {/* Carousel Section */}
        <div className="carousel">
          <Slider {...settings}>
          <div>
              <img src="IMG_1028.jpg" alt="meme" />
            </div>
            <div>
              <img src="IMG_1029.jpg" alt="meme" />
            </div>
            <div>
              <img src="k1.png" alt="meme" />
            </div>
            <div>
              <img src="k2.png" alt="meme" />
            </div>
            <div>
              <img src="k3.png" alt="meme" />
            </div>
            <div>
              <img src="k4.png" alt="meme" />
            </div>
            <div>
              <img src="k5.png" alt="meme" />
            </div>
            <div>
              <img src="k6.png" alt="meme" />
            </div>
            <div>
              <img src="k7.png" alt="meme" />
            </div>
            <div>
              <img src="k8.png" alt="meme" />
            </div>
            <div>
              <img src="k9.png" alt="meme" />
            </div>
            <div>
              <img src="k10.png" alt="meme" />
            </div>
          </Slider>
        </div>
        <div className="links">
        <a href="https://t.me/OGchillguy" target="_blank" rel="noopener noreferrer">
          <img src="telegram.png" alt='telegram'/>
          </a>
          <a href="https://x.com/i/communities/1859082290732638707" target="_blank" rel="noopener noreferrer">
          <img src="twitter.png" alt='twitter'/>\
          </a>
          <a href="https://www.reddit.com/r/OGchillguy/s/Jve5ofk9VO" target="_blank" rel="noopener noreferrer">
          <img src="reddit.png" alt='reddit'/>
          </a>
          <a href="https://www.tiktok.com/@realogchillguy?_t=8rZBzy1D5kB&_r=1" target="_blank" rel="noopener noreferrer">
          <img src="tiktok.png" alt="tiktok"/>
          </a>
          <a href="https://www.tiktok.com/@realogchillguy?_t=8rZBzy1D5kB&_r=1" target="_blank" rel="noopener noreferrer">
          <img src="dex.webp" alt="dexscreener"/>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;